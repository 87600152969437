import React, { useEffect } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TutorialContainer = styled.div`
  width: 100%;
`;

const Section = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  padding: 80px 40px;
  background-color: ${props => props.backgroundColor};
  gap: 60px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 60px 20px;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin-right: 40px;

  @media (max-width: 1024px) {
    margin-right: 0;
    max-width: 100%;
  }
`;

const Title = styled.h2`
  font-size: 3.2rem;
  color: #1B5E20;
  margin-bottom: 40px;
  font-weight: 700;
  line-height: 1.2;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const SubSection = styled.div`
  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
  padding: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.06);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const SubTitle = styled.h3`
  font-size: 1.8rem;
  color: #2E7D32;
  margin-bottom: 20px;
  font-weight: 600;
`;

const Content = styled.div`
  color: #333;
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 20px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 20px 0;
`;

const ListItem = styled.li`
  display: flex;
  align-items: start;
  margin-bottom: 15px;
  color: #333;
  font-size: 1.1rem;
  line-height: 1.6;

  &:before {
    content: "•";
    color: #4CAF50;
    font-weight: bold;
    margin-right: 10px;
    font-size: 1.4rem;
    line-height: 1;
  }
`;

const VideoContainer = styled.div`
  flex: 1;
  position: sticky;
  top: 40px;
  height: fit-content;
  min-width: 500px;

  @media (max-width: 1024px) {
    position: relative;
    top: 0;
    min-width: 100%;
  }
`;

const Video = styled.video`
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0,0,0,0.1);
`;

const sections = [
  {
    id: 'leads',
    title: "Adding Your Leads",
    backgroundColor: "rgba(206, 235, 170, 0.816)",
    videoSrc: "/leads-tutorial.mp4",
    content: [
      {
        subtitle: "Import Your Leads File",
        content: "Our system makes importing contacts simple and flexible. You can import Excel (.xlsx) or CSV files containing your contact information. While phone numbers are the only required field, you can also include WhatsApp group names. The system will automatically handle any empty fields, making the import process seamless.",
        list: [
          "Import Excel (.xlsx) or CSV files",
          "Phone numbers are required",
          "Optional WhatsApp group names",
          "Automatic empty field handling"
        ]
      },
      {
        subtitle: "Add Individual Leads",
        content: "Perfect for testing or adding single contacts, our individual leads feature offers a straightforward approach:",
        list: [
          "Select country code from the dropdown menu",
          "Enter the phone number",
          "Add a contact name",
          "Click Add and select the number to include in your campaign"
        ]
      }
    ]
  },
  {
    id: 'business',
    title: "Creating Your Business Profile",
    backgroundColor: "rgba(186, 215, 150, 0.816)",
    videoSrc: "/business-tutorial.mp4",
    content: [
      {
        subtitle: "Business Name Display",
        content: "Your business profile is crucial - it's what recipients see when they receive your message.",
        list: [
          "Basic users: Messages will be sent under the 'Sendue' business name",
          "Enterprise users: Can display their own business name",
          "Contact us to learn more about Enterprise subscriptions"
        ]
      },
      {
        subtitle: "Profile Setup",
        content: "Make your profile visually appealing and professional:",
        list: [
          "Add an image that represents your business",
          "This will be visible to all recipients",
          "Preview how recipients will see your image in the render section",
          "Add 'This message was sent using the Sendue platform' for clarifying the sender name"
        ]
      }
    ]
  },
  {
    id: 'post',
    title: "Creating Your Post",
    backgroundColor: "rgba(166, 195, 130, 0.816)",
    videoSrc: "/post-tutorial.mp4",
    content: [
      {
        subtitle: "Post Content",
        content: "Start by identifying your post:",
        list: [
          "Give your post a name for easy identification",
          "Write your main message text",
          "Maximum 10 emojis per message (WhatsApp regulation)",
          "See exactly how recipients will view your message"
        ]
      },
      {
        subtitle: "Media and Buttons",
        content: "Enhance your message with visual content:",
        list: [
          "Add images or videos to your post",
          "Add up to 4 interactive buttons",
          "Preview how recipients will see your media in the render section",
          "Make buttons clear and engaging"
        ]
      }
    ]
  },
  {
    id: 'deploy',
    title: "Campaign Deployment",
    backgroundColor: "rgba(146, 175, 110, 0.816)",
    videoSrc: "/deploy-tutorial.mp4",
    content: [
      {
        subtitle: "Campaign Overview",
        content: "Review and launch your campaign:",
        list: [
          "Review selected leads list",
          "Confirm business profile setup",
          "Verify post content and design",
          "Set campaign schedule"
        ]
      },
      {
        subtitle: "Credits and Confirmation",
        content: "Manage your campaign credits and track progress:",
        list: [
          "Check your current credit balance",
          "Each message requires one credit",
          "Receive deployment confirmation email",
          "Monitor delivery progress"
        ]
      }
    ]
  }
];

const Tutorial = () => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <TutorialContainer>
      {sections.map((section) => (
        <Section
          key={section.id}
          id={section.id}
          backgroundColor={section.backgroundColor}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <ContentContainer>
            <Title>{section.title}</Title>
            {section.content.map((subsection, index) => (
              <SubSection key={index}>
                <SubTitle>{subsection.subtitle}</SubTitle>
                <Content>{subsection.content}</Content>
                <List>
                  {subsection.list.map((item, i) => (
                    <ListItem key={i}>{item}</ListItem>
                  ))}
                </List>
              </SubSection>
            ))}
          </ContentContainer>

          <VideoContainer>
            <Video autoPlay loop muted playsInline>
              <source src={section.videoSrc} type="video/mp4" />
            </Video>
          </VideoContainer>
        </Section>
      ))}
    </TutorialContainer>
  );
};

export default Tutorial;