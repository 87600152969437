import React from 'react';
import Navbar from '../Landing_page/Navbar';
import contactImage from '../Landing_page/assets/contact_image.png';

const ContactPage = () => {
    const styles = {
        contactContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 20px',
            minHeight: '100vh',
            backgroundColor: 'transparent',
            marginTop: '40px',
        },
        card: {
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderRadius: '10px',
            padding: '30px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            maxWidth: '600px',
            width: '100%',
            marginBottom: '20px',
            textAlign: 'center',
        },
        title: {
            fontSize: '24px',
            marginBottom: '20px',
            color: '#333',
        },
        content: {
            fontSize: '16px',
            color: '#555',
            marginBottom: '20px',
        },
        emailLink: {
            textDecoration: 'none',
            color: '#4CAF50',
            fontWeight: 'bold',
        },
        imageContainer: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '20px',
        },
        contactImage: {
            maxWidth: '100%',
            height: 'auto',
        },
        phoneLink: {
            textDecoration: 'none',
            color: '#4CAF50',
            fontWeight: 'bold',
        },
        subtitle: {
            fontSize: '18px',
            color: '#333',
            marginTop: '15px',
            marginBottom: '10px',
            fontWeight: 'bold',
        },
    };
  
    return (
        <>
            <Navbar />
            <div style={styles.contactContainer}>
                <div style={styles.card}>
                    <h2 style={styles.title}>Contact Us</h2>
        
                    <p style={styles.content}>
                        We're here to help and answer any question you might have. We look forward to hearing from you!
                    </p>
        
                    <div style={styles.imageContainer}>
                        <img src={contactImage} alt="Contact Us" style={styles.contactImage} />
                    </div>
        
                    <div style={{ textAlign: 'left' }}>
                        <h3 style={{ ...styles.title, fontSize: '20px', marginTop: '20px' }}>Contact Information</h3>
                        <h4 style={styles.subtitle}>Sendue Marketing</h4>
                        <p style={styles.content}><strong>Email:</strong> <a href="mailto:aharon@sen-due.com" style={styles.emailLink}>aharon@sen-due.com</a></p>
                        <p style={styles.content}><strong>Email:</strong> <a href="mailto:yosef@sen-due.com" style={styles.emailLink}>yosef@sen-due.com</a></p>
          
                        <h3 style={{ ...styles.title, fontSize: '20px', marginTop: '20px' }}>Partnership Inquiries</h3>
                        <p style={styles.content}>For partnership opportunities, please email us at <a href="mailto:partnerships@sendue.com" style={styles.emailLink}>partnerships@sendue.com</a></p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactPage;